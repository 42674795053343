import React, { useRef, useState, useMemo, Fragment } from 'react';
import styled, { css } from 'styled-components';

import { useIntersection } from 'context/useIntersection';
import Heading from 'libs/heading';
import LazyImage from 'components/LazyImage';
import Spacing from 'layouts/Spacing';

const Wrap = styled.div`
	background-color: ${p => p.theme.colors.black};
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 0;
`;

const Picture = styled.div`
	position: relative;
	background-color: ${p => p.theme.colors.black};
	height: 100vh;
	contain: strict;
	overflow: hidden;
	overflow: clip;
	pointer-events: none;
	scroll-snap-align: center;
	scroll-snap-stop: always;
	z-index: -10;
	${p =>
		p.$active &&
		css`
			pointer-events: auto;
			opacity: 1 !important;
		`};

	${p =>
		p.$hasPrev &&
		css`
			opacity: 1 !important;
		`};

	${p =>
		p.$active &&
		p.$darken &&
		css`
			filter: brightness(0.5) grayscale(0.12) contrast(0.85);
		`};

	.lazy-image {
		height: 100%;
		width: 100%;
	}

	& [role='img'] {
		position: absolute;
		inset: 0;
	}

	img {
		-webkit-touch-callout: none;
		bottom: 0;
		height: 100%;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		object-fit: cover;
	}

	@media screen {
		@supports (position: sticky) {
			order: -1;
			position: sticky;
			top: 0;
			margin-top: 0;
			margin-bottom: 0;
			opacity: 0;
			transition: opacity 750ms ease-in-out 200ms,
				filter 750ms ease-in-out 200ms;

			&:not(:first-child) {
				margin-top: -100vh;
			}
		}
	}
`;

const Card = styled.div`
	margin-top: 0;
	padding: 0 16px 50vh;
	pointer-events: none;
	position: relative;
	z-index: 15;

	${p =>
		p.$last &&
		css`
			padding-bottom: 0 !important;
		`}

	@media screen {
		@supports (position: sticky) {
			margin-top: 0;

			${p =>
				p.$first &&
				css`
					margin-top: -45vh;
				`}
		}
	}
`;

const CardContainer = styled.div`
	will-change: opacity;
	transform: translateY(-50%);
	opacity: 0;
	transition: opacity 0.75s cubic-bezier(0.48, 0.01, 0.6, 1);
	margin: 0 auto;
	max-width: 720px;
	width: fit-content;
	padding: 0;
	pointer-events: auto;
	text-align: center;
	z-index: 15;

	${p =>
		p.$active &&
		css`
			opacity: 1;
		`};

	p {
		background-color: ${p => p.theme.colors.white};
		padding: 10px 15px;
		box-decoration-break: clone;
		border-radius: ${p => p.theme.utils.borderRadius};
		font-size: 22px;
		line-height: 30px;
		font-weight: 500;
		margin: 0;
		${p =>
			p.theme.media.mediumOnly(css`
				font-size: 24px;
				line-height: 34px;
			`)}
		${p =>
			p.theme.media.large(css`
				font-size: 28px;
				line-height: 40px;
				padding: 15px 20px;
			`)}
	}
`;

const Final = styled.div`
	span {
		display: block;
		font-size: 30px;
		line-height: 42px;
		margin-top: 10px;
		${p =>
			p.theme.media.large(css`
				font-size: 40px;
				line-height: 52px;
			`)}
	}
`;

const FinalTitle = styled(Heading)`
	color: ${p => p.theme.colors.white};
	font-weight: 700;
`;

const SubText = styled.p`
	margin: 0;
	padding-bottom: 0;
	padding-top: 30px;
	text-transform: uppercase;
	font-weight: 500;
	color: ${p => p.theme.colors.white};
	background-color: transparent !important;
	${p =>
		p.theme.media.medium(css`
			padding-top: 60px !important;
			font-size: 19px !important;
			line-height: 29px !important;
		`)}
`;

/**
 * A ScrollyTelling component for displaying a series of images with scroll-triggered effects.
 *
 * This component allows the display of a sequence of images with scroll-triggered effects.
 * It provides options for customizing the title, subtitle, images, and settings.
 *
 * @component
 * @param {Object} props - The props object for the ScrollyTelling component.
 * @param {string} [props.title] - The title for the ScrollyTelling section.
 * @param {string} [props.subtitle] - The subtitle for the ScrollyTelling section.
 * @param {Array<Object>} [props.images] - An array of image objects to be displayed.
 * @param {Object} [props.mainImage] - The main image object.
 * @param {Array<string>} [props.settings] - An array of settings for customizing the component.
 * @param {number} [props.index] - The index of the component.
 * @returns {JSX.Element|null} The rendered ScrollyTelling component JSX element or null if no images are provided.
 */
export default function ScrollyTelling({
	title = '',
	subtitle = '',
	images = [],
	mainImage = {},
	settings = [],
	...props
}) {
	/**
	 * Ref for the ScrollyTelling container.
	 */
	const containerRef = useRef(null);

	/**
	 * State to track the active element.
	 */
	const [activeElement, setActiveElement] = useState(0);

	/**
	 * Combine all images, including the main image, into a single array.
	 */
	const allImages =
		(mainImage?.file?.url && [
			...images,
			{
				...mainImage,
				image: mainImage,
			},
		]) ||
		images;

	/**
	 * Create a memoized intersection observer handler function.
	 */
	const handler = useMemo(
		() => (entry, index) => {
			// Check if the entry is intersecting

			if (entry.isIntersecting) {
				setActiveElement(index);
			}
		},
		[]
	);

	/**
	 * Use the intersection observer to trigger the handler.
	 */
	useIntersection(containerRef, '.scrolly-section', handler, {
		rootMargin: '0px 0px 0px 0px',
		threshold: 0.25,
		root: null,
	});

	/**
	 * If no images are provided, return null.
	 */
	if (!allImages?.length > 0) return null;

	/**
	 * Replace small images with the main image if necessary.
	 */
	const smallImagesReplaced = allImages.map(card =>
		!card?.finale &&
		(card?.image?.height < 1100 || card?.image?.width < 2000)
			? (card.image = mainImage.image)
			: card
	);

	/**
	 * If no  images are available, return null.
	 */
	if (!smallImagesReplaced?.length > 0) return null;

	return (
		<Spacing
			style={{ position: 'relative', marginTop: '0' }}
			className="component__scrolly-telling"
			data-cy="component__scrolly-telling"
			{...props}>
			<Wrap
				ref={containerRef}
				$pageType={props?.pageType}
				id="scrolly-telling"
				style={{ minHeight: '100vh' }}
				$reachedEnd={activeElement + 1 === allImages?.length}
				aria-label={`Viser bilde ${activeElement + 1} av ${
					allImages?.length
				}.`}>
				{smallImagesReplaced?.map((card, i) => {
					if (!card?.image?.file?.url) return null;

					return (
						<Fragment key={i}>
							<CardOutput
								active={activeElement}
								cardIndex={i}
								cardData={card}
								imageCount={allImages?.length}
								componentindex={props?.index || 0}
								title={title}
								subtitle={subtitle}
								subtitleInMainTitle={settings?.includes(
									'Vis undertittel som en del av hovedtittel, bare mindre'
								)}
								{...props}
							/>
						</Fragment>
					);
				})}
			</Wrap>
		</Spacing>
	);
}

/**
 * Renders a card output based on the provided card data and active status.
 *
 * This function takes card data and active status as input and renders a card output with a picture,
 * depending on whether the card is active or not.
 *
 * @param {Object} props - The props object for the CardOutput component.
 * @param {Object} [props.cardData] - The data for the card, including image information.
 * @param {boolean} [props.active=false] - Indicates if the card is active.
 * @param {number} [props.cardIndex=0] - The index of the card within the component.
 * @param {number} [props.imageCount] - The total number of images in the component.
 * @param {number} [props.componentindex] - The index of the component containing the card.
 * @returns {JSX.Element} The rendered CardOutput component JSX element.
 */
function CardOutput({
	cardData = {},
	active = false,
	cardIndex = 0,
	...props
}) {
	return (
		<>
			<Picture
				$hasPrev={active !== cardIndex && cardIndex < active}
				$active={active === cardIndex}
				$darken={cardIndex + 1 === props?.imageCount}
				aria-disabled={active !== cardIndex}
				aria-current={active === cardIndex}>
				{(cardData?.image?.file?.url && (
					<LazyImage
						{...cardData?.image}
						load="instant"
						fit="fill"
						width={2500}
						height={2000}
						sizes={[
							{ w: 2500, h: 2000 },
							{ w: 1600, h: 1400 },
							{ w: 1200, h: 1000 },
							{ w: 768, h: 517 },
						]}
					/>
				)) ||
					null}
			</Picture>
			<Card
				$first={cardIndex === 0}
				$last={
					props?.componentindex === 0 &&
					cardIndex + 1 === props?.imageCount
				}>
				<CardContainer
					aria-disabled={active !== cardIndex}
					aria-current={active === cardIndex}
					className={`scrolly-section${
						(cardIndex + 1 === props?.imageCount && ' is-last') ||
						''
					}`}
					$active={active === cardIndex}>
					<Caption
						cardData={cardData}
						cardIndex={cardIndex}
						componentindex={props?.componentindex}
						{...props}
					/>
				</CardContainer>
			</Card>
		</>
	);
}

/**
 * Renders a caption for a scrollytelling card.
 *
 * This function takes props including card data, component and card indices, and image count to render a caption
 * for a scrollytelling card. The caption content may vary based on whether the card is the last one in the sequence.
 *
 * @param {Object} props - The props object for the Caption component.
 * @param {Object} [props.cardData] - The data for the card, including title, subtitle, and date.
 * @param {number} [props.cardIndex=0] - The index of the card within the scrollytelling component.
 * @param {number} [props.imageCount] - The total number of images in the scrollytelling component.
 * @param {number} [props.componentindex] - The index of the scrollytelling component.
 * @param {string} [props.title] - The title of the scrollytelling section.
 * @param {string} [props.subtitle] - The subtitle of the scrollytelling section.
 * @param {string} [props.date] - The date associated with the card data.
 * @param {boolean} [props.isfirstheading=false] - Indicates if the first heading level is an <h1> tag.
 * @param {boolean} [props.subtitleInMainTitle=false] - Indicates whether the subtitle should be included in the main title.
 * @returns {JSX.Element} The rendered Caption component JSX element.
 */
function Caption({ ...props }) {
	/**
	 * Conditionally renders a paragraph based on the card's position within a scrollytelling sequence.
	 * If the card is either not the last one in the sequence or the componentindex is not zero,
	 * it renders the card's title as a paragraph.
	 */
	if (
		(props?.componentindex !== 0 &&
			props?.cardIndex + 1 === props?.imageCount) ||
		props?.cardIndex + 1 !== props?.imageCount
	) {
		return <p>{props?.cardData?.title}</p>;
	}

	// The card is the last within a scrollytelling sequence and renderst markup
	return (
		<Final>
			<FinalTitle
				level={(props?.isfirstheading && 'h1') || 'h2'}
				data-cy="scrollytelling-title"
				className={props?.isfirstheading && 'large'}>
				{(props?.subtitleInMainTitle && props?.title && (
					<>
						{props?.title}
						{props?.subtitle && <span>{props?.subtitle}</span>}
					</>
				)) || (
					<>
						{props?.title}

						{(props?.subtitle || props?.date) && (
							<SubText>{props?.subtitle || props?.date}</SubText>
						)}
					</>
				)}
			</FinalTitle>
		</Final>
	);
}
